import React from 'react';
import PropTypes from 'prop-types';
import Text from '@firsttable/web-components/atoms/Text';
import Title from '@firsttable/web-components/atoms/Title';
import Button from '@firsttable/web-components/atoms/Button';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import { freeCreditAmount } from '@firsttable/functions';
import { graphql } from 'gatsby';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import SEO from '../components/seo';
import { isGlobalSite } from '../helpers/data';
import NavButton from '../components/atoms/NavButton';

const ColumnOne = ({ data: { siteConfig } }) => {
  if (isGlobalSite) {
    return (
      <SEO meta={[
        { 'http-equiv': 'refresh', content: '0;url=/' },
      ]}
      />
    );
  }
  return (
    <>
      <Title as="h2">Congratulations, you did it!</Title>
      <Text>
        We like smart people. It&lsquo;s always worth searching for a promo code, a
        coupon code or a special discount – and we like smart people who do just that.
      </Text>
      <Text>
        So you definitely deserve
        a
        {' '}
        <strong>
          {freeCreditAmount(siteConfig)}
          {' '}
          free credit
        </strong>
        . Just grab the following secret
        promo code and use it when you sign up:
      </Text>
      <Title as="h4" textAlign="center">iamsmart</Title>
      <Button NavComponent={NavButton} type="button" kind="cta" to="/auth/sign-up?code=iamsmart">Sign up now</Button>
    </>
  );
};

ColumnOne.propTypes = {
  data: PropTypes.shape({
    siteConfig: PropTypes.object,
  }),
};

const FirstTablePromoCode = (props) => (
  <TwoColumnLayout
    col1={<ColumnOne {...props} />}
    col2={<ContactCTA NavButtonComponent={NavButton} />}
  />
);

export default FirstTablePromoCode;

export const query = graphql`
  query promoCodePage {
    siteConfig {
      currencySymbol
      currency
      country
      freeCreditAmount
    }
  }
`;
